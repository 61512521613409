<template>
  <div id="app">
    <div class="full-page">
      <Header></Header>
      <div class="container">
        <router-view/>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
}

html
{
  height: 100vh;
  background-color: black;
}

.full-page{
  background-color: black;
}
</style>
